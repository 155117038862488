
/* -------------------------------- */
/* Login / Register / Onboard */
/* -------------------------------- */

.start-right-inner .header-container {
  flex-direction: row !important;
}


@media screen and (max-width: 980px) {

  .start-left-inner .header-logo {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .onboarding-step-number {
    width: 35px;
    height: 35px;
    font-size: 1rem;
  }

  .onboarding-step-container p {
    font-size: 1rem;
  }

  .step-spacer {
    height: 20px;
    margin-left: 17px;
  }

}


@media screen and (max-width: 820px) {

  .onboarding-step-container p {
    max-width: 100px;
    font-size: .9rem;
  }

}


@media screen and (max-width: 700px) {

  .start-right-inner {
    padding: 30px;
  }
  .start-left-column.onboarding {
    display: none;
  }

  .onboarding-pricing-buttons-container {
    gap: var(--paddingPrimary);
    flex-wrap: wrap;
  }

  .onboarding-price-button  {
    padding: 8px 12px;
    font-size: .9rem;
  }

}


@media screen and (max-width: 500px) {

  .start-left-column {
    display: none;
  }

  .onboarding-price-slider-container {
    padding: 0 10px;
  }

  .onboarding-pricing-buttons-container div.flex-column {
    width: calc(50% - 30px);
    margin-bottom: 15px;
  }

}


@media screen and (max-width: 450px) {

  .start-right-column.onboarding .start-right-inner {
    padding: 30px;
  }

  .start-right-inner form {
    margin-top: var(--paddingSecondary);
  }

  div.onboarding-big-button.sub-text {
    padding: initial;
  }

  .flex-row.onboarding-big-buttons-wrapper.plans {
    flex-direction: column;
  }

  .flex-row.onboarding-big-buttons-wrapper.plans .onboarding-big-button {
    width: 100%;
  }

  .flex-row.onboarding-big-buttons-wrapper.plans .onboarding-big-button ul {
    margin: 15px 0;
  }

  .onboarding-big-button.community.sub-text.desktop {
    display: none;
  }

  .onboarding-big-button.community.sub-text.mobile {
    display: block !important;
    text-align: center;
    margin-top: -15px;
    margin-bottom: 10px;
  }

}


@media screen and (max-width: 400px) {

  h3.v2 {
    font-size: 1.125rem;
  }

  h4.v2 {
    font-size: 1rem;
  }
}


@media screen and (max-width: 380px) {

  .start-right-column.onboarding .start-right-inner {
    padding: var(--paddingPrimary);
  }

  .onboarding-price-button  {
    padding: 8px 10px;
    font-size: .8rem;
  }

  .onboarding-price-slider-container {
    padding: 0 5px;
  }

}





/* -------------------------------- */
/* Nav */
/* -------------------------------- */

@media screen and (max-width: 1050px) {

  .logo-lockup {
    width: 100px;
  }

  p.header-admin {
    font-size: 1.125rem;
  }

  .header-container .link {
    padding: 10px !important;
  }

  .go-to-flow {
    padding-left: var(--paddingPrimary);
    padding-right: var(--paddingPrimary);
    margin-left: var(--paddingPrimary);
  }

  .profile-name {
    padding-left: var(--paddingPrimary);
    padding-right: var(--paddingPrimary);
  }

}

@media screen and (max-width: 850px) {

  .logo-lockup {
    width: 90px;
    height: 28px;
  }

  p.header-admin {
    font-size: 1rem;
  }

  .header-container .link,
  .go-to-flow,
  .profile-name  {
    font-size: .9rem;
  }

}


@media screen and (max-width: 785px) {

  .header-container {
    flex-direction: column;
  }

  .nav {
    justify-content: center;
    width: 100%;
    border-top: 1px solid var(--grayTertiary);
  }

  .go-to-flow,
  .profile-name {
    padding: var(--paddingPrimary);
  }

}


@media screen and (max-width: 560px) {

  .go-to-flow,
  .profile-name {
    display: none;
  }

}




/* -------------------------------- */
/* Groups Index */
/* -------------------------------- */

@media screen and (max-width: 750px) {

  .groups-charts-container {
    flex-direction: column;
  }

  .lesson-data-chart-container {
    width: 100% !important;
  }

  .learner-stats-container {
    width: 100% !important;
    margin-top: -45px;
  }

  .stat-box-container {
    height: 150px;
  }

  .stat-box-container h4 {
    font-size: 1.75rem;
    margin-bottom: initial;
  }

}


@media screen and (max-width: 550px) {

  .group-card {
    width: 100%;
  }

}





/* -------------------------------- */
/* Group */
/* -------------------------------- */

@media screen and (max-width: 900px) {

  .group-details-left {
    padding-right: var(--paddingPrimary);
  }

  .group-details-right {
    padding-left: var(--paddingPrimary);
  }

  .group-details-right,
  .group-details-right-left,
  .group-details-right-right,
  .group-details-right-right .top-stats {
    gap: var(--paddingPrimary);
  }

}


@media screen and (max-width: 750px) {

  .learner-table-controls {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--paddingPrimary);
  }

  .learner-table-controls div {
    width: 100%;
  }

  .learner-table-controls .flex-row .flex-row {
    justify-content: flex-start;
  }

  .learner-table-controls select {
    width: auto;
  }

}


@media screen and (max-width: 700px) {

  .group-details-container {
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--paddingPrimary);
  }

  .group-details-left {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--paddingPrimary);
    width: 100%;
    font-size: .9rem;
    text-align: center;
    padding-right: initial;
    border-right: initial;
  }

  .group-details-left div {
    width: 100%;
    max-width: calc(50% - 30px);
  }

  .group-details-left .registration-link-container {
    width: 100%;
    max-width: 100%;
  }


  .unhas-course-data .flex-row {
    flex-direction: column;
  }

}


@media screen and (max-width: 650px) {

  .table-learner-row-data.course-data {
    display: none;
  }

  .table-learner-row-data {
    flex-grow: 1;
  }

}


@media screen and (max-width: 530px) {

  .group-details-right {
    display: none;
  }

}


@media screen and (max-width: 475px) {

  .group-details-container {
    font-size: .9rem;
  }

  .group-detail-item {
    width: 100%;
  }

  .group-detail-divider {
    display: none;
  }

  .table-learner-row-one {
    flex-direction: column;
    gap: var(--paddingPrimary);
  }

  .table-learner-row-one .table-learner-row-data {
    width: 100%;
  }

  .learner-table-controls div {
    flex-wrap: wrap;
    gap: var(--paddingPrimary);
  }

  .table-learner-row-email {
    max-width: 50vw;
  }

}





/* -------------------------------- */
/* Invite */
/* -------------------------------- */

@media screen and (max-width: 800px) {

  .invite-learners-container form.flex-row {
    flex-direction: column;
  }

}


@media screen and (max-width: 650px) {

  .flex-row.invite-learners-left {
    flex-direction: column;
    width: 100%;
  }

  .flex-row.invite-learners-left div,
  .flex-row.invite-learners-left input,
  .flex-row.invite-learners-left select {
    width: 100%;
  }

}



/* -------------------------------- */
/* Seats */
/* -------------------------------- */

@media screen and (max-width: 615px) {

  .group-details-container {
    gap: var(--paddingSecondary) !important;
  }

}